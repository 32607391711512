<template>
  <div id="app">
    <header>
      <div class="header-container">
          <h1>Corey M. Bryant</h1>
      </div>
    </header>

    <main>
      <!-- About Section -->
      <section id="about" class="about">
        <div class="content-container">
          <img :src="require('@/assets/headshot.png')"/>
          <div class="text-content">
            <h3>about me</h3>
            <p>
            Computational scientist with 10 years of analytics experience across
            the healthcare, information technology, and financial industries.
            Skilled in mathematical modeling, predictive analytics, and machine
            learning; well-versed in scientific computing including Linux, MPI,
            and software development in Python and C/C++.&nbsp;
            </p>
          </div>
        </div>
      </section>

      <!-- Services Section -->
      <section id="services" class="services">
        <div class="service-grid">
          <div class="service-box">
            <h4>LinkedIn</h4>
            <p>
            You can find my professional profile on <a
            href="https://www.linkedin.com/in/corey-m-bryant-phd-a2957a80/">LinkedIn</a>
            </p>
          </div>
          <div class="service-box">
            <h4>Professional Interests</h4>
            <p>
            <ul>
                <li> Uncertainty Quantification </li>
                <li> Predictive Modeling </li>
                <li> Machine Learning </li>
            </ul>
            </p>
          </div>
          <div class="service-box">
            <h4>Publications</h4>
            <p> 
            My research publications can be found on <a
            href="https://scholar.google.com/citations?hl=en&amp;view_op=list_works&amp;gmla=AJsN-F6gwJoQkugsUJHwl2DScrrut8upwS8frPkVTlDAKqK-txuOxR9ulkv3c9Y_UimQHHsJqbL2ea-vnC2p2RZWfcw9SHFr9w&amp;user=AjGCEL0AAAAJ"
            target="_blank">Google Scholar</a>.
            </p>
          </div>
        </div>
      </section>

      <!-- Testimonials and Contact Section -->
      <section id="contact" class="contact">
        <div>
          <h3>I'm also an avid homebrewer</h3>
          <p>
          <a href="https://rationalbrewing.com" class="contact-button">see what's on tap</a>
          </p>
        </div>
      </section>
    </main>

    <footer>
      <p>&copy; 2024 Corey M. Bryant. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const name = ref('');
    const email = ref('');
    const message = ref('');



    const isMenuOpen = ref(false);
    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    return { name, email, message, isMenuOpen, toggleMenu };
  }
};
</script>

<style>
/* Global Styles */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


#app {
  text-align: center;
}

/* Header */
header {
  background-color: rgba(255, 255, 255, 0.9); /* Lighter color with transparency */
  color: #282c34;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: left;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 10px;
}

h1 {
  margin: 0;
}

nav {
  margin: 0;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
  margin: 0;
}

.nav-links a {
  color: #282c34;
  text-decoration: none;
  font-weight: 500;
  position: relative;
  padding: 8px 12px; /* Increased padding for better click area */
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  border-radius: 4px;
}

.nav-links a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #61dafb;
  transform: scaleX(0);
  transform-origin: bottom left;
  transition: transform 0.3s ease;
}

.nav-links a:hover::before {
  transform: scaleX(1);
}

.nav-links a:hover {
  /* background-color: #61dafb; */
  color: #282c34;
  /* box-shadow: 0 0 0 2px #282c34 inset; /1* Add inset box-shadow for hover effect *1/ */
}

/* Hamburger menu styles */
.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
  padding: 0 20px;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: #000;
}

/* Responsive styles */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    text-align: center;
    gap: 10px;
  }

  .nav-links.show {
    display: flex;
  }

  .hamburger {
    display: flex;
  }
}

/* Main Sections */
main {
  padding-top: 80px; /* Adjust padding to avoid content being hidden behind the fixed header */
}

/* Section Styles */
.content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
  position: relative;
}

.section-image {
  width: 50%;
  max-width: 400px;
  height: auto;
  margin: 0 20px;
}

.text-content {
  width: 100%;
  text-align: center; /* Center text in Home section */
}

h2, h3 {
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
}

/* Home Section */
.home {
  background-color: #f4f4f4;
  padding: 80px 20px;
}

.home .text-content h2 {
  font-size: 2.5rem;
}

.home .text-content p {
  font-size: 1.25rem;
}

/* About Section */
.about {
  background-color: #282c34;
  color: white;
  padding: 80px 20px;
}

.about .content-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 20px;
}

.about .text-content h3 {
  font-size: 4rem;
}

.about .text-content {
  text-align: left; /* Align text to the left */
  font-size: 1.25rem;
}


.about img {
  padding: 0px 80px ;
  max-width: 200px;
  height: auto;
  margin: 0px 20px;
  border-radius: 50%;
  justify-self: center;
}


@media (max-width: 768px) {
  .about .content-container {
      display: grid;
      grid-template-columns: 1fr;
      align-items: start;
      justify-content: space-around;
      gap: 20px;
  }

    .about img {
      padding: 0px 20px ;
      max-width: 200px;
      height: auto;
      margin: 0px 20px;
      border-radius: 50%;
      justify-self: center;
    }
}

.section-image {
  width: 20%;
  max-width: 400px;
  height: auto;
}

/* Services Section */
.services {
  /* background-image: url('@/assets/taps.jpg'); */
  /* background-position: center; */
  color: #282c34;
  padding: 80px 20px;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  align-content: center;
}

.service-box {
  /* background-color: white; */
  background-color: #77aad9;
  border: 1px solid #282c34;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  font-size: 1rem;
}

/* Testimonials and Contact Section */
.contact {
  background-image: linear-gradient(0deg, rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('@/assets/taps.jpg');
  background-position: center;
  background-size: cover;
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  padding: 80px 20px;
}

.contact h3 {
  color: white;
  font-size: 2rem;
}

.contact-button {
  padding: 10px 20px;
  background-color: #77aad9;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  border: none;
  cursor: pointer;
}

.contact-button:hover {
  color: white;
}

footer {
  background-color: #282c34;
  color: white;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}
</style>

